import React, { useEffect, useContext } from "react";
import { ReducerContext } from "./../../../reducer/context";
import { UserDataContext } from "../UserData";
import { SegmentsContext } from "./../../../context/Segments";
import { Confirm } from "./../../share/InsightUI";
import QuickReport from "../QuickReport";
import QuickFilter from "./../QuickFilter";
import Toast from "./../../share/Toast";
import LoaderWithText from "./../../share/LoaderWithText";
import Alert from "../../share/Alert";
import "./style.scss";
import { pushProfileViewEvent } from "../../../utils";
import { AuthContext } from "../../auth/FirebaseAuthContext";
import SideBarNav from "../SideBarNav";
import TopBarNav from "../TopBarNav";

const ProfileLayout = (param) => {
  const profileId = param.computedMatch.params.profileId;
  document.title = param.title;
  const title = document.title;
  const TitleIcon = param.titleIcon;
  const description = param.description;
  const children = param.children;
  const showQuickFilter = param.quickFilter;
  const showQuickReport = param.quickReport;
  const showCompareMode = param.compareAbility || param.reportCompareAbility;
  const type = param.type;
  const isBeta = param.isBeta;
  const isTeamOnly = param.isTeamOnly;

  const {
    activeProfileId,
    setActiveProfileId,
    setQueryId,
    apiServer,
    isUserHasProfileAccess,
    access,
    isApiServerLoading,
  } = useContext(UserDataContext);
  const { state } = useContext(ReducerContext);
  const { authUser } = useContext(AuthContext);

  const { isInitialSegmentsLoaded } = useContext(SegmentsContext);

  // Push account and profile into dataLayer
  useEffect(() => {
    let profileFound = false;
    access.accounts.forEach((account) => {
      if (profileFound) return;

      account.profiles.forEach((profile) => {
        if (profileFound) return;

        if (profile.id.toString() === profileId) {
          pushProfileViewEvent(authUser.user, {
            accountid: account.id,
            accountName: account.name,
            profileid: profileId,
            profileName: profile.name,
            role: authUser?.isAdmin ? "admin" : "viewer",
          });

          profileFound = true;
        }
      });
    });
  }, [profileId, access, authUser]);

  useEffect(() => {
    if (profileId !== activeProfileId) {
      setActiveProfileId(profileId);
      setQueryId(null);
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.src = "/theme.js";
    document.head.appendChild(script);
    return () => script.parentNode.removeChild(script);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show loader while loading profile data or waiting for admin status
  if (
    isApiServerLoading ||
    !apiServer ||
    !isInitialSegmentsLoaded ||
    typeof authUser?.isProfileAdmin === "undefined"
  ) {
    return <LoaderWithText text="Loading profile..." className="mt-5 pt-5" />;
  }

  // Show access denied after profile data is loaded or if profile not found (404)
  if (!isUserHasProfileAccess || access?.error?.code === 404) {
    return (
      <Alert
        show={true}
        type={"danger"}
        message={
          "You don't have access to the requested URL. Please talk to your administrator if you believe you should have access."
        }
        count={0}
      />
    );
  }

  // Only render content when everything is ready
  return (
    <div id="wrapper">
      <TopBarNav />

      <div className="d-flex wrapper-content">
        <SideBarNav profileId={profileId} />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <div className={`layout-header ${showQuickFilter ? "sticky" : ""}`}>
              {!showQuickReport && (
                <div
                  className={`d-sm-flex align-items-center justify-content-between ${
                    showQuickFilter ? "mb-4" : ""
                  }`}
                >
                  <div className="d-flex align-items-center">
                    {!!TitleIcon && (
                      <span className="nav-menu-icon mr-3">
                        <TitleIcon />
                      </span>
                    )}
                    <span className="page-title">{title}</span>
                    {isBeta && (
                      <span className="badge badge-pill badge-primary ml-2">
                        BETA
                      </span>
                    )}
                  </div>
                </div>
              )}
              {showQuickReport && (
                <QuickReport
                  title={title}
                  titleIcon={TitleIcon}
                  type={type}
                  description={description}
                  showCompareMode={showCompareMode}
                  isBeta={isBeta}
                  isTeamOnly={isTeamOnly}
                />
              )}

              {showQuickFilter && (
                <QuickFilter showCompareMode={showCompareMode} type={type} />
              )}
            </div>
            <div className="container-fluid">
              {React.Children.map(children, (child) =>
                React.isValidElement(child)
                  ? React.cloneElement(child, { isTeamOnly })
                  : child
              )}
            </div>
          </div>
        </div>
      </div>

      <Toast />
      {state.isConfirmModalVisible && (
        <Confirm
          title={state.confirmModalConfig.title}
          text={state.confirmModalConfig.text}
          confirmText={state.confirmModalConfig.confirmText}
          confirmAction={state.confirmModalConfig.confirmAction}
        />
      )}
    </div>
  );
};

export default ProfileLayout;
