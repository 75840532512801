import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  Fragment,
} from "react";
import "./style.scss";
import { UserDataContext } from "../../../../components/app/UserData";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import {
  captureExceptionToSentry,
  ellipsize,
  generateCondition,
  generateEventCondition,
  isEmptyValue,
  kSeparatorDecimal,
  renderIcon,
  SortIcon,
} from "../../../../utils";
import api from "../../../../api";
import { SegmentsContext } from "../../../../context/Segments";
import Table from "../../../../components/share/Table";
import { Tooltip as ReactTooltip } from "react-tooltip";
import StackedBarChart from "../../../../components/share/StackedBarChart";
import { FaDesktop, FaMobileAlt } from "react-icons/fa";
import { FaLaptop } from "react-icons/fa6";
import Pagination from "../../../../components/app/Pagination";
import { Button } from "../../../../components/share/InsightechUI";
import { useReportModal } from "../../../../components/app/QuickReport/hooks";
import httpStatusCodeMap from "../../../../helpers/httpStatusCodeMap";
import { DEFAULT_ERROR, ERRORS_MAPPED } from "../../../../constants/error";
import Alert from "../../../../components/share/Alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const eventTypes = require("../../../../inc/eventFilters.json");
const subEventTypes = require("../../../../inc/subEventsFilters.json");
const errorByTypes = require("../../../../inc/errorByTypes.json"); // Error types for the dropdown, replace later if API able to handle this

const ErrorsDashboard = (props) => {
  const profileId = props.match.params.profileId;
  const isTeamOnly = props.isTeamOnly;
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [queryId, setQueryId] = useState(null);
  const [key, setKey] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });
  const [selectedErrorType, setSelectedErrorType] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("sessions");
  const [sortOrder, setSortOrder] = useState("desc");

  const { startDate, endDate, appliedFilters, featureFlags } =
    useContext(UserDataContext);
  const { authUser } = useContext(AuthContext);
  const { selectedSegments } = useContext(SegmentsContext);
  const { handleClickSubmitTempError } = useReportModal(props);
  const history = useHistory();

  const handleApiError = (error, loaderToggle) => {
    if (api.isCancel(error)) {
      return;
    }
    const errMsg = error.response?.data?.result;
    if (ERRORS_MAPPED[errMsg]) {
      setAlert({
        show: true,
        type: "danger",
        message: ERRORS_MAPPED[errMsg],
        count: alert.count + 1,
      });
    } else {
      setAlert({
        show: true,
        type: "danger",
        message: DEFAULT_ERROR,
        count: alert.count + 1,
      });
      captureExceptionToSentry(error);
    }

    loaderToggle(false);
  };

  const readErrorsList = ({ queryId, key, page, pageSize }) => {
    api.cancel("readErrorsList");
    api
      .readErrorsList(authUser, { profileId, queryId, key, page, pageSize })
      .then((res) => {
        if (res.data.status === 100) {
          readErrorsList({ queryId, key, page, pageSize });
        } else if (res.data.status === 200) {
          setData(res.data.data);
          setTotalRows(res.data.data.totalRows);
          setIsLoadingData(false);
          setIsDataLoaded(true);
        } else {
          setIsLoadingData(false);
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const fetchErrorsList = () => {
    setIsLoadingData(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });
    api.cancel("queryErrorsList");
    api.cancel("readErrorsList");

    // Base params object
    const params = {
      profileId,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      filter: JSON.stringify(appliedFilters),
      segments: JSON.stringify(segmentsArray),
      errorTypes:
        selectedErrorType && selectedErrorType !== "all"
          ? JSON.stringify([selectedErrorType])
          : "",
      textSearch: searchText,
      sort: `${sortField}-${sortOrder}`,
    };

    api
      .queryErrorsList(authUser, params)
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          setQueryId(queryId);
          setKey(key);
          readErrorsList({ queryId, key, page, pageSize });
        } else {
          setIsLoadingData(false);
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const AnalyticsActionButton = ({
    className,
    onClick,
    tooltipContent,
    icon,
    ...rest
  }) => (
    <Button
      className={`analytics-action ${className}`}
      onClick={onClick}
      data-tooltip-id="errors-dash-tooltip"
      data-tooltip-content={tooltipContent}
      {...rest}
    >
      {icon}
    </Button>
  );
  const generateErrorCondition = (errorType, errorObject) => {
    const errorAttributes = eventTypes[errorType]?.attributes || [];
    const errorSubAttributes = subEventTypes[errorType]?.attributes || [];

    // Generate conditions from errorAttributes
    const attributeConditions = Object.entries(errorObject)
      .filter(
        ([key, value]) => errorAttributes.includes(key) && !isEmptyValue(value)
      )
      .map(([key, value]) => {
        // Use "startsWith" operator for JsErrorMessage, "is" for others
        const operator = key === "JsErrorMessage" ? "startsWith" : "is";
        return generateCondition(key, operator, [String(value)]);
      });

    // Generate conditions from errorSubAttributes (entries not in errorAttributes)
    const subAttributeConditions = Object.entries(errorObject)
      .filter(
        ([key, value]) =>
          !errorAttributes.includes(key) &&
          (errorSubAttributes.includes(key) ||
            key === "ApiErrorEndpointCleanUrl") &&
          !isEmptyValue(value)
      )
      .map(([key, value]) => {
        return generateCondition(key, "is", [String(value)]);
      });

    // Combine both sets of conditions
    const allConditions = [...attributeConditions, ...subAttributeConditions];

    return [
      {
        inclusion: true,
        eventConditions: [generateEventCondition(errorType, allConditions)],
      },
    ];
  };

  const handleErrorTypeChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const errorType = selectedOption.getAttribute("data-key");
    setSelectedErrorType(errorType);
  };

  const handleAnalyticsClick = (e, payload, destination) => {
    const { errorType, errorObject } = payload;
    const errorCondition = generateErrorCondition(errorType, errorObject);

    // Construct error name based on error type, similar to tooltip content in Description column
    let errorName = `Error: ${eventTypes[errorType]?.label || errorType}`;

    switch (errorType) {
      case "RageClick": {
        const text = errorObject?.Text || "";
        errorName += ` • on LINK "${text}"`;
        break;
      }
      case "JsError": {
        const errorMessage = errorObject?.JsErrorMessage || "";
        errorName += ` • ${errorMessage}`;
        break;
      }
      case "ApiError": {
        const statusCode = errorObject?.ApiErrorHttpStatus;
        const endpoint = errorObject?.ApiErrorEndpointCleanUrl || "";
        errorName += ` • ${
          httpStatusCodeMap[statusCode] || statusCode
        } • on ${endpoint}`;
        break;
      }
      case "ErrorPage": {
        const pageTitle = errorObject?.ErrorPageTitle || "Page Not Found";
        const pageUrl = errorObject?.PageCleanUrl || "";
        errorName += ` • ${pageTitle} • on ${pageUrl}`;
        break;
      }
      case "ErrorMessage": {
        const message = errorObject?.ErrorMessageText || "";
        const pageUrl = errorObject?.PageCleanUrl || "";
        errorName += ` • "${message}" on ${pageUrl}`;
        break;
      }
      // No default case needed as we already set the base errorName
    }

    handleClickSubmitTempError(e, errorCondition, destination, errorName);
  };

  // Handle search text change
  const handleKeywordChange = (e) => {
    setSearchText(e.target.value);
  };

  // Handle key press in search input
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchError();
    }
  };

  // Search for an error by text
  const handleSearchError = () => {
    fetchErrorsList();
  };

  const handleClickHeaderSort = (e) => {
    // Find the closest element with the data-sort-field attribute
    // This handles cases where the click might be on a child element
    const target = e.target.closest("[data-sort-field]");
    if (!target) return;

    let order = sortOrder;
    const field = target.dataset["sortField"];

    // Do not reverse order if field changes
    if (field !== sortField) {
      setSortField(field);
      return;
    }

    // Toggle sort order
    if (order === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }

    setSortField(field);
    setSortOrder(order);
  };

  const columns = useMemo(() => {
    let c = [
      {
        Header: "View Analytics",
        Cell: (param) => {
          const { row } = param;
          const payload = row.original;

          return (
            <div className="action-buttons">
              <AnalyticsActionButton
                className="solid"
                onClick={(e) => handleAnalyticsClick(e, payload, "replay_list")}
                tooltipContent="View Replays"
                icon={renderIcon("Replays", { color: "white" })}
                data-payload={payload}
                data-index={row.index}
              />
              <AnalyticsActionButton
                className="outlined"
                onClick={(e) => handleAnalyticsClick(e, payload, "dashboard")}
                tooltipContent="View Stats"
                icon={renderIcon("Dashboard")}
                data-payload={payload}
                data-index={row.index}
              />
            </div>
          );
        },
      },
      {
        Header: "Error Type",
        accessor: "errorType",
        Cell: ({ value }) => {
          const errorColors = {
            RageClick: "error-rage", // Orange
            ErrorPage: "error-notfound", // Grey
          };

          return (
            <span
              className={`error-type ${errorColors[value] || "error-default"}`}
            >
              <span className="error-icon">
                {renderIcon(eventTypes[value]?.key)}
              </span>
              <span className="error-label">
                {eventTypes[value]?.label || value}
              </span>
            </span>
          );
        },
      },
      {
        Header: "Description",
        accessor: "errorObject",
        Cell: ({ value, row }) => {
          const { errorType } = row.original;

          const getErrorCodeText = (errorCode) => {
            return httpStatusCodeMap[errorCode] || errorCode;
          };

          if (errorType === "RageClick") {
            const text = value?.Text || "";
            return (
              <span
                data-tooltip-id="errors-dash-tooltip"
                data-tooltip-html={`
                  <b>Rage Click</b><br>
                  <b>Text : </b>${text}<br>
                  <b>CSS Selector : </b>${value?.CSSSelector}<br>
                  <b>Clean Url : </b>${value?.PageCleanUrl} <br>
                  <b>Tag Name : </b>&lt;${value?.TagName}&gt;`}
              >
                <b>Rage Click</b> &#8226; on LINK "{ellipsize(text, 70)}"
              </span>
            );
          } else if (errorType === "JsError") {
            const errorMessage = value?.JsErrorMessage || "";
            return (
              <span
                data-tooltip-id="errors-dash-tooltip"
                data-tooltip-html={`
                  <b>${eventTypes[errorType]?.label}</b><br>
                  <b>Error Message : </b>${errorMessage}<br>
                  <b>Js Error Filename : </b>${value?.JsErrorFilename}<br>
                  <b>Js Error Line : </b>${value?.JsErrorLine}<br>
                  <b>Js Error Column : </b>${value?.JsErrorCol}<br>
                  `}
              >
                {ellipsize(errorMessage, 70)}
              </span>
            );
          } else if (errorType === "ApiError") {
            const statusCode = value?.ApiErrorHttpStatus;
            const endpointUrl = value?.ApiErrorEndpointCleanUrl || "";
            return (
              <span
                data-tooltip-id="errors-dash-tooltip"
                data-tooltip-html={`
                  <b>${getErrorCodeText(statusCode)}</b><br>
                  <b>Clean Url : </b>${endpointUrl}<br>
                  <b>Status Code : </b>${statusCode}<br>
                  <b>Api Error Method : </b>${value?.ApiErrorMethod}`}
              >
                <b>{getErrorCodeText(statusCode)}</b>
                {endpointUrl && <> • on {ellipsize(endpointUrl, 70)}</>}
              </span>
            );
          } else if (errorType === "ErrorPage") {
            const pageTitle =
              ellipsize(value?.ErrorPageTitle, 35) || "Page Not Found";
            const pageUrl = value?.PageCleanUrl || "";
            return (
              <span
                data-tooltip-id="errors-dash-tooltip"
                data-tooltip-html={`
                  <b>${pageTitle}</b><br>
                  <b>Clean Url : </b>${pageUrl}<br>
                  <b>Referer : </b>${value?.Referer}`}
              >
                <b>{pageTitle}</b> &#8226; on {ellipsize(pageUrl, 70)}
                {!!value?.Referer && (
                  <> &#8226; referer: {ellipsize(value.Referer, 70)}</>
                )}
              </span>
            );
          } else if (errorType === "ErrorMessage") {
            const message = value?.ErrorMessageText || "";
            const pageUrl = value?.PageCleanUrl || "";
            return (
              <span
                data-tooltip-id="errors-dash-tooltip"
                data-tooltip-html={`
                  <b>Error Message : </b>${message}<br>
                  <b>Clean Url : </b>${pageUrl}`}
              >
                <b>"{ellipsize(message, 35)}"</b> on {ellipsize(pageUrl, 70)}
              </span>
            );
          }

          return <span>Unknown error</span>;
        },
      },
      {
        Header: () => (
          <div
            className="pageHeader"
            onClick={handleClickHeaderSort}
            data-sort-field="sessions"
          >
            Sessions
            <SortIcon
              currentSortField={sortField}
              columnField="sessions"
              currentSortOrder={sortOrder}
            />
          </div>
        ),
        accessor: "nbSessions",
        Cell: ({ value }) => {
          return <span>{value || 0}</span>;
        },
      },
      {
        Header: () => (
          <div
            className="pageHeader"
            onClick={handleClickHeaderSort}
            data-sort-field="conversion-rate"
          >
            Conversion %
            <SortIcon
              currentSortField={sortField}
              columnField="conversion-rate"
              currentSortOrder={sortOrder}
            />
          </div>
        ),
        accessor: "assistedConversionRate",
        Cell: ({ value }) => {
          return <span>{kSeparatorDecimal(value * 100, 2, 2)}%</span>;
        },
      },
      {
        Header: "Device Type",
        accessor: "nbDesktop",
        Cell: ({ value, row }) => {
          const desktop = value || 0;
          const mobile = row.original.nbMobile || 0;
          const tablet = row.original.nbTablet || 0;

          // Use the reusable StackedBarChart component
          const segments = [
            {
              value: desktop,
              color: "#8DD8F7", // Desktop color
              label: "Desktop",
              icon: <FaDesktop className="device-icon" />,
            },
            {
              value: mobile,
              color: "#CAE2AF", // Mobile color
              label: "Mobile",
              icon: <FaMobileAlt className="device-icon" />,
            },
            {
              value: tablet,
              color: "#A8B1DE", // Tablet color
              label: "Tablet",
              icon: <FaLaptop className="device-icon" />,
            },
          ];

          return (
            <div className="browser-type-container">
              <StackedBarChart
                segments={segments}
                tooltipId="errors-dash-tooltip"
              />
            </div>
          );
        },
      },
    ];

    return c;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sortField, sortOrder]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Cancel API requests
      api.cancel("queryErrorsList");
      api.cancel("readErrorsList");
    };
  }, []);

  useEffect(() => {
    if (profileId && (featureFlags.hasErrorReport || isTeamOnly)) {
      fetchErrorsList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileId,
    startDate,
    endDate,
    appliedFilters,
    selectedSegments,
    selectedErrorType,
    sortField,
    sortOrder,
  ]);

  // Pagination
  useEffect(() => {
    if (queryId && key) {
      setIsLoadingData(true);
      readErrorsList({ queryId, key, page, pageSize });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  // Redirect to the upgrade page
  useEffect(() => {
    if (!featureFlags.hasErrorReport && !isTeamOnly) {
      history.push(`/profile/${profileId}/plan-upgrade`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags.hasErrorReport, profileId, isTeamOnly]);

  return (
    <Fragment>
      <Alert show={alert.show} type={alert.type} message={alert.message} />
      <div className="error-table-container">
        <div className="d-flex justify-content-between">
          <div className="search-url form-inline">
            <div className="search-area">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search errors by text ..."
                value={searchText}
                onChange={handleKeywordChange}
                onKeyDown={handleKeyDown} // Add keydown event handler
              />
              <div className="search-button">
                <Button
                  size="flag"
                  color="aluminum"
                  onClick={handleSearchError}
                >
                  search
                </Button>
              </div>
            </div>
            <select
              className="form-control form-control-sm"
              onChange={handleErrorTypeChange}
              value={selectedErrorType}
            >
              <option value="all" data-key="all">
                All Error Types
              </option>
              {Object.entries(errorByTypes).map(([key, value]) => (
                <option key={key} value={key} data-key={key}>
                  {value}
                  {!!data?.totalByType &&
                    selectedErrorType === "all" &&
                    ` (${data.totalByType[key] || 0})`}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Table
          hScroll
          id="table-errors"
          data={data.records || []}
          columns={columns}
          isLoading={isLoadingData}
          isDataLoaded={isDataLoaded}
          loadingText={
            <>
              <i className="fa fa-spinner spin"></i> Loading Errors...
            </>
          }
        />
        {!isLoadingData && (
          <Pagination
            activePage={page + 1}
            totalRows={totalRows}
            pageSize={pageSize}
            changeHandler={(pageNum, pageSize) => {
              setPage(pageNum - 1);
              setPageSize(pageSize);
            }}
          />
        )}
      </div>
      <ReactTooltip
        id="errors-dash-tooltip"
        className="tooltip-xl tooltip-with-url"
      />
    </Fragment>
  );
};

export default ErrorsDashboard;
