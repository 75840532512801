import React from "react";
import TextareaField from "../../../../components/share/fields/TextareaField";

const ApiErrorSettings = ({
  apiErrorEndpointsIgnored,
  handleApiErrorEndpointsIgnored,
  isApiErrorEndpointsIgnoredValid,
  apiErrorEndpointsWithPayload,
  handleApiErrorEndpointsWithPayload,
  isApiErrorEndpointsWithPayload,
  disabled,
}) => {
  return (
    <>
      <TextareaField
        text={apiErrorEndpointsIgnored.join("\n")}
        placeholder=""
        label="Endpoints To Ignore"
        id="api-error-endpoint-ignore"
        helpText="Every API calls to an URL starting with one of these items will be ignored. Errors will not be captured."
        handleChange={handleApiErrorEndpointsIgnored}
        isValid={isApiErrorEndpointsIgnoredValid}
        disabled={disabled}
      />
      <TextareaField
        text={apiErrorEndpointsWithPayload.join("\n")}
        placeholder=""
        label="Endpoints To Monitor With Payload"
        id="api-error-endpoint-with-payload"
        helpText="Every API calls to an URL starting with one of these items will be monitored. Errors will be captured with user's payload."
        handleChange={handleApiErrorEndpointsWithPayload}
        isValid={isApiErrorEndpointsWithPayload}
        disabled={disabled}
      />
    </>
  );
};

export default ApiErrorSettings;
